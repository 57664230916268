// src/App.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setColorScheme, sendUserData } from './store/userSlice';
import { getUserData, applyTheme } from './utils/telegramUtils';
import './App.css';
import TabsComponent from './components/TabsComponent';

function App() {
  const dispatch = useDispatch();
  const { user, colorScheme } = useSelector((state) => state.user);

  useEffect(() => {
    // Получаем данные о пользователе и цветовой схеме
    const { userData, scheme } = getUserData();
    
    // Устанавливаем данные в Redux
    dispatch(setUser(userData));
    dispatch(setColorScheme(scheme));
    
    // Отправляем данные пользователя на сервер
    if (userData) {
      dispatch(sendUserData(userData))
        .unwrap()
        .then((response) => {
          console.log('User data sent successfully:', response);
        })
        .catch((error) => {
          console.error('Error sending user data:', error);
        });
    }
  }, [dispatch]);

  return (
    <div>
      <TabsComponent />
      
    </div>
  );
}

export default App;
