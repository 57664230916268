// services/tagsService.js

export const fetchTags = async (userId) => {
    const response = await fetch(`/api/tags?userId=${userId}`);
    return await response.json();
};

export const fetchAntitags = async (userId) => {
    const response = await fetch(`/api/antitags?userId=${userId}`);
    return await response.json();
};

export const updateTags = async (userId, tags) => {
    await fetch('/api/tags', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, tags }),
    });
};

export const updateAntitags = async (userId, antitags) => {
    await fetch('/api/antitags', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, antitags }),
    });
};
