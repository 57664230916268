// src/utils/telegramUtils.js

// Функция получения данных о пользователе и цветовой схеме
export const getUserData = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
  
      const userData = webApp.initDataUnsafe.user;
      const scheme = webApp.colorScheme === 'dark' ? 'Dark' : 'Light';
  
      return { userData, scheme };
    }
    return { userData: null, scheme: 'Unknown' };
  };
  
  // Функция для применения темы
  export const applyTheme = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
      const themeParams = webApp.themeParams;
  
      // Применяем значения темы в качестве CSS переменных
      if (themeParams) {
        document.documentElement.style.setProperty('--tg-theme-bg-color', themeParams.bg_color || '#ffffff');
        document.documentElement.style.setProperty('--tg-theme-text-color', themeParams.text_color || '#000000');
        document.documentElement.style.setProperty('--tg-theme-hint-color', themeParams.hint_color || '#888888');
        document.documentElement.style.setProperty('--tg-theme-link-color', themeParams.link_color || '#0000ff');
        document.documentElement.style.setProperty('--tg-theme-button-color', themeParams.button_color || '#007bff');
        document.documentElement.style.setProperty('--tg-theme-button-text-color', themeParams.button_text_color || '#ffffff');
        document.documentElement.style.setProperty('--tg-theme-secondary-bg-color', themeParams.secondary_bg_color || '#f8f9fa');
        document.documentElement.style.setProperty('--tg-theme-header-bg-color', themeParams.header_bg_color || '#ffffff');
        document.documentElement.style.setProperty('--tg-theme-bottom-bar-bg-color', themeParams.bottom_bar_bg_color || '#ffffff');
        document.documentElement.style.setProperty('--tg-theme-accent-text-color', themeParams.accent_text_color || '#000000');
        document.documentElement.style.setProperty('--tg-theme-section-bg-color', themeParams.section_bg_color || '#ffffff');
        document.documentElement.style.setProperty('--tg-theme-section-header-text-color', themeParams.section_header_text_color || '#000000');
        document.documentElement.style.setProperty('--tg-theme-section-separator-color', themeParams.section_separator_color || '#e0e0e0');
        document.documentElement.style.setProperty('--tg-theme-subtitle-text-color', themeParams.subtitle_text_color || '#666666');
        document.documentElement.style.setProperty('--tg-theme-destructive-text-color', themeParams.destructive_text_color || '#ff0000');
      }
    }
  };
  