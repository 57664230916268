// src/components/SearchComponent.js
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { FaSearch, FaTimes } from 'react-icons/fa'; // Импортируем иконки из react-icons
import { useDispatch } from 'react-redux';
import { fetchPosts } from '../store/postsSlice'; // Импортируем экшн для поиска
import './SearchComponent.css';

const SearchComponent = () => {
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();

    const handleSearch = () => {
        dispatch(fetchPosts(query)); // Вызов экшена для поиска постов
    };

    const handleClear = () => {
        setQuery('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="search-component">
            <div className="search-wrapper">
                <FaSearch className="search-icon" />
                <InputText 
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyPress={handleKeyPress} // Обработчик нажатий клавиш
                    placeholder="Введите запрос"
                    className="search-input"
                    type="search" // Тип поля
                    inputMode="search" // Включаем режим поиска для клавиатуры
                />
                {query && (
                    <FaTimes className="clear-icon" onClick={handleClear} />
                )}
            </div>
        </div>
    );
};

export default SearchComponent;