import React from 'react';
import { Card } from 'primereact/card';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import './PostsList.css';

const PostsList = () => {
    const posts = useSelector((state) => state.posts.posts);

    return (
        <div className="posts-list">
            {Array.isArray(posts) && posts.length === 0 ? (
                <p>Нет результатов</p>
            ) : (
                Array.isArray(posts) && posts.map(post => (
                    <Card key={post.message_id} title={post.chat_info} subTitle={new Date(post.date).toLocaleDateString()}>
                        <ReactMarkdown>{post.message_text}</ReactMarkdown>
                    </Card>
                ))
            )}
        </div>
    );
};

export default PostsList;
