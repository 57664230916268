// src/components/TabsComponent.js
import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import SearchComponent from './SearchComponent';
import PostsList from './PostsList';
import TagsManager from './TagsManager';
import { FaHome, FaSearch, FaCog } from 'react-icons/fa';
import CheckConnection from './CheckConnection';
import UserInfo from './UserInfo';
import './TabsComponent.css';


const TabsComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="tabview-container">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header={<FaSearch />} headerClassName="tab-icon">
                <div className="tab-panel-container">
                    <SearchComponent />
                    <div className="posts-list">
                        <PostsList />
                    </div>
                </div>
            </TabPanel>
            <TabPanel header={<FaCog />} headerClassName="tab-icon">
                <TagsManager />
            </TabPanel>
        </TabView>
    </div>
);
};

export default TabsComponent;
