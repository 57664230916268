import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Асинхронное действие для отправки данных на сервер
export const sendUserData = createAsyncThunk(
  'user/sendUserData',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    colorScheme: 'Unknown',
    status: 'idle', // Статус запроса
    error: null, // Ошибка запроса
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setColorScheme(state, action) {
      state.colorScheme = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendUserData.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(sendUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setUser, setColorScheme } = userSlice.actions;
export default userSlice.reducer;
