import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chips } from 'primereact/chips';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './TagsManager.css';
import { fetchTags, fetchAntitags, updateTags, updateAntitags } from '../services/tagsService';

const TagsManager = () => {
    const userId = useSelector((state) => state.user.user.id); // Получение ID пользователя из Redux Store
    const [showTags, setShowTags] = useState(false);
    const [showAntitags, setShowAntitags] = useState(false);
    const [tags, setTags] = useState([]);
    const [antitags, setAntitags] = useState([]);

    useEffect(() => {
        // Загрузка тегов и антитегов при загрузке компонента
        const loadTagsAndAntitags = async () => {
            if (userId) {
                const fetchedTags = await fetchTags(userId);
                const fetchedAntitags = await fetchAntitags(userId);
                setTags(fetchedTags);
                setAntitags(fetchedAntitags);
            }
        };

        loadTagsAndAntitags();
    }, [userId]);

    const handleTagsChange = (e) => {
        const newTags = e.value;
        setTags(newTags);
        updateTags(userId, newTags).catch((error) => console.error('Error updating tags:', error));
    };

    const handleAntitagsChange = (e) => {
        const newAntitags = e.value;
        setAntitags(newAntitags);
        updateAntitags(userId, newAntitags).catch((error) => console.error('Error updating antitags:', error));
    };

    return (
        <div className="tags-manager">
            {/* Теги */}
            <div className="field-toggle" onClick={() => setShowTags(!showTags)}>
                <h4>Управление тегами</h4>
                <FontAwesomeIcon icon={showTags ? faChevronUp : faChevronDown} />
            </div>
            {showTags && (
                <Chips value={tags} onChange={handleTagsChange} placeholder="Введите теги" />
            )}

            {/* Антитеги */}
            <div className="field-toggle" onClick={() => setShowAntitags(!showAntitags)}>
                <h4>Управление антитегами</h4>
                <FontAwesomeIcon icon={showAntitags ? faChevronUp : faChevronDown} />
            </div>
            {showAntitags && (
                <Chips value={antitags} onChange={handleAntitagsChange} placeholder="Введите антитеги" />
            )}
        </div>
    );
};

export default TagsManager;
